import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"politika-konfidencijnosti"} />
		<Helmet>
			<title>
				HAVEN RETREAT
			</title>
			<meta name={"description"} content={"У Haven Retreat ми створили місце, де розкіш і природа поєднуються. Наші ексклюзивні спа-програми та релаксаційні процедури дозволять вам зануритися у стан спокою та насолоди. Відновіть енергію тіла та душі в атмосфері бездоганної гармонії та затишку."} />
			<meta property={"og:title"} content={"HAVEN RETREAT"} />
			<meta property={"og:description"} content={"У Haven Retreat ми створили місце, де розкіш і природа поєднуються. Наші ексклюзивні спа-програми та релаксаційні процедури дозволять вам зануритися у стан спокою та насолоди. Відновіть енергію тіла та душі в атмосфері бездоганної гармонії та затишку."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ed228be778710018d371a1/images/bigstock-Spa-Accessories-99070442.webp?v=2024-09-20T10:47:40.772Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ed228be778710018d371a1/images/bigstock-Spa-Accessories-99070442.webp?v=2024-09-20T10:47:40.772Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ed228be778710018d371a1/images/bigstock-Spa-Accessories-99070442.webp?v=2024-09-20T10:47:40.772Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ed228be778710018d371a1/images/bigstock-Spa-Accessories-99070442.webp?v=2024-09-20T10:47:40.772Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ed228be778710018d371a1/images/bigstock-Spa-Accessories-99070442.webp?v=2024-09-20T10:47:40.772Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ed228be778710018d371a1/images/bigstock-Spa-Accessories-99070442.webp?v=2024-09-20T10:47:40.772Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ed228be778710018d371a1/images/bigstock-Spa-Accessories-99070442.webp?v=2024-09-20T10:47:40.772Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header23 />
		<Section padding="60px 0" background="--color-light" quarkly-title="PrivacyPolicy">
			<Override slot="SectionContent" flex-direction="column" align-items="center" />
			<Text
				as="h2"
				font="--headline2"
				margin="0px 0px 40px 0px"
				text-align="center"
				color="--dark"
			>
				Політика конфіденційності Haven Retreat
			</Text>
			<Box width="80%" max-width="800px">
				<Text font="--base" margin="0px 0px 20px 0px" color="--dark">
					Ваша конфіденційність важлива для нас. Ця політика конфіденційності описує, як Haven Retreat збирає, використовує та захищає вашу особисту інформацію, коли ви відвідуєте наш вебсайт або користуєтесь нашими послугами.
				</Text>
				<Text as="h3" font="--headline3" margin="20px 0px 10px 0px" color="--dark">
					1. Інформація, яку ми збираємо
				</Text>
				<Text font="--base" margin="0px 0px 20px 0px" color="--dark">
					Ми можемо збирати такі типи інформації:
				</Text>
				<Text font="--base" margin="0px 0px 10px 0px" color="--dark">
					• Особиста інформація: ім'я, електронна адреса, номер телефону, адреса проживання.
				</Text>
				<Text font="--base" margin="0px 0px 10px 0px" color="--dark">
					• Інформація про платежі: дані кредитної або дебетової картки, платіжні дані.
				</Text>
				<Text font="--base" margin="0px 0px 10px 0px" color="--dark">
					• Технічна інформація: IP-адреса, тип браузера, операційна система, час доступу до сайту.
				</Text>
				<Text as="h3" font="--headline3" margin="20px 0px 10px 0px" color="--dark">
					2. Використання інформації
				</Text>
				<Text font="--base" margin="0px 0px 20px 0px" color="--dark">
					Ми використовуємо вашу інформацію для:
				</Text>
				<Text font="--base" margin="0px 0px 10px 0px" color="--dark">
					• Обробки ваших бронювань та надання послуг.
				</Text>
				<Text font="--base" margin="0px 0px 10px 0px" color="--dark">
					• Надсилання важливої інформації щодо вашого бронювання або послуг.
				</Text>
				<Text font="--base" margin="0px 0px 10px 0px" color="--dark">
					• Поліпшення нашого сервісу та персоналізації ваших вражень.
				</Text>
				<Text font="--base" margin="0px 0px 10px 0px" color="--dark">
					• Відправлення маркетингових матеріалів, якщо ви на це погодились.
				</Text>
				<Text as="h3" font="--headline3" margin="20px 0px 10px 0px" color="--dark">
					3. Захист даних
				</Text>
				<Text font="--base" margin="0px 0px 20px 0px" color="--dark">
					Ми впроваджуємо різні технічні та організаційні заходи для захисту вашої особистої інформації від несанкціонованого доступу, втрати або розголошення. Ми використовуємо шифрування для збереження конфіденційності ваших даних під час передачі інформації.
				</Text>
				<Text as="h3" font="--headline3" margin="20px 0px 10px 0px" color="--dark">
					4. Передача третім особам
				</Text>
				<Text font="--base" margin="0px 0px 20px 0px" color="--dark">
					Ми не продаємо та не передаємо вашу особисту інформацію третім особам без вашої згоди, за винятком випадків, коли це необхідно для надання послуг або виконання правових зобов’язань.
				</Text>
				<Text as="h3" font="--headline3" margin="20px 0px 10px 0px" color="--dark">
					5. Ваші права
				</Text>
				<Text font="--base" margin="0px 0px 20px 0px" color="--dark">
					Ви маєте право доступу до ваших персональних даних, їх виправлення або видалення, обмеження обробки даних, а також право заперечити проти їх використання в маркетингових цілях. Щоб скористатися цими правами, зв'яжіться з нами через наші контактні дані.
				</Text>
			</Box>
		</Section>
		<Components.Footer123 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});